import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useLocation,
    Switch,
    Route,
    useHistory,
    BrowserRouter,
    useRouteMatch,
} from 'react-router-dom';
import loadable from '@loadable/component';
import cn from 'classnames';
import { hot } from 'react-hot-loader/root';
//import { Alert } from 'components/layout/Alert/Alert';
import { Header, Footer } from 'components';
import { Outline } from '../Outline/Outline';
//import PopupSupport from '../../components/PopupSupport/PopupSupport';

import routes from 'routes';
import './App.scss';
import '../../assets/css/style.css';
//import '../../assets/css/media.css';
import '../../assets/css/fonts.css';
import '../../assets/css/article.css';
import '../../assets/css/animate.css';
import '../../assets/css/media/1600/media.css';
import '../../assets/css/media/1000/media.css';
import '../../assets/css/media/640/media.css';
import '../../assets/css/media/340/media.css';

//import '../../pages/Home/Home.scss';
//import '../../pages/Home/Start.scss';
//import '../../pages/Home/Rooms.scss';
//import '../../pages/Home/Choice.scss';

import Page404 from 'pages/404/404';

import { State } from 'types/redux';

//import { currentUser } from 'store/ducks/currentUser/actions';
//import Popup from 'components/Popup/Popup';

interface IWindowConfig {
    ym?: any;
}

type WindowType = Window & typeof globalThis & IWindowConfig;

function App() {
    const dispatch = useDispatch();
    //const [popupRefuse, setPopupRefuse] = useState(false);
    //const [openPopupSupport, setOpenPopupSupport] = useState(false);
    const location = useLocation();
    const match = useRouteMatch();
    //const history = useHistory();
    //const { statusUser, user } = useSelector(
    //    (state: any) => state.currentUserReducer
    //);

    const [mainClass, setMainClass] = useState<string>('');
    useEffect(() => {
        let className: string = '';
        if (location) {
            if (location.pathname === '/') {
                className = 'home';
            } else if (location.pathname.indexOf('products/') > -1) {
                className = 'white-header';
            }
        }
        setMainClass(className);
    }, [location.pathname]);

    const is404 = useSelector((state: State) => state.notFound.is404);

    return (
        <div className={mainClass}>
            {!is404 && <Header />}
            <div className={cn('wrapper')}>
                <>
                    <Switch>
                        {routes.map(({ fetchData, ...routeProps }, index) => {
                            return <Route key={index} {...routeProps} />;
                        })}

                        <Route path="*" exact component={Page404} />
                    </Switch>
                </>
            </div>
            {!is404 && <Footer />}
        </div>
    );
}

const Component = hot(App);

export { Component as App };
