import * as React from 'react';
import { useEffect } from 'react';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { Header, Page } from 'components';
import './404.scss';
import { PageMeta } from 'components/PageMeta/PageMeta';
import { set404 } from 'store/ducks/404/actions';
import { useDispatch } from 'react-redux';

type Props = {
    code: number;
    children: React.ReactNode;
};

// Component is used for passing http status for server side rendering;
// For example, if page isn't found, client give page with 404 status code
const Status = ({ code, children }: Props) => {
    const render = ({ staticContext }: RouteComponentProps) => {
        if (staticContext) {
            staticContext.statusCode = code;
        }

        return children;
    };

    return <Route render={render} />;
};

export default function NotFoundPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(set404(true));
        return () => {
            dispatch(set404(false));
        };
    }, []);
    return (
        <Status code={404}>
            <PageMeta
                title="404"
                description="We can't seem to find the page you're looking for"
            />
            <section className="page-404__section">
                <div className="page-404__content">
                    <div className="page-404__logo">
                        <Link to="/">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="169"
                                height="18"
                                viewBox="0 0 169 18"
                                fill="none"
                            >
                                <path
                                    d="M41.3438 0.9783V17.7002H44.9035V10.7293H53.0015V7.67133H44.9035V4.03629H53.1771V0.9783H41.3438Z"
                                    fill="black"
                                />
                                <path
                                    d="M57.7252 10.5042H63.6916C63.6163 9.35101 62.8144 7.82153 60.7079 7.82153C58.7278 7.82153 57.8757 9.30084 57.7252 10.5042ZM60.7079 5.28945C64.3188 5.28945 66.7756 7.99714 66.7756 11.9583V12.6608H57.7754C57.9761 14.1893 59.2045 15.4679 61.2599 15.4679C62.3879 15.4679 63.7167 15.0163 64.4935 14.2646L65.8975 16.32C64.6942 17.423 62.7893 18 60.9096 18C57.2244 18 54.4414 15.5181 54.4414 11.6322C54.4414 8.1226 57.0227 5.28945 60.7079 5.28945Z"
                                    fill="black"
                                />
                                <path
                                    d="M68.75 5.59031H71.9334V7.24522C72.7855 6.16642 74.2899 5.31434 75.7943 5.31434V8.42346C75.5694 8.37328 75.2683 8.3231 74.9171 8.3231C73.8643 8.3231 72.4603 8.92427 71.9334 9.70201V17.6987H68.75V5.59031Z"
                                    fill="black"
                                />
                                <path
                                    d="M78.6654 14.6901V8.37249H76.6602V5.58954H78.6654V2.28065H81.8739V5.58954H84.3307V8.37249H81.8739V13.8381C81.8739 14.5898 82.2753 15.1659 82.9768 15.1659C83.4535 15.1659 83.9051 14.9903 84.0807 14.8156L84.7572 17.2213C84.2814 17.6487 83.4284 17.999 82.0997 17.999C79.8687 17.999 78.6654 16.8468 78.6654 14.6901Z"
                                    fill="black"
                                />
                                <path
                                    d="M86.3174 5.58982H89.5008V17.6982H86.3174V5.58982ZM85.9922 1.90461C85.9922 0.851824 86.8693 -0.000268936 87.898 -0.000268936C88.9498 -0.000268936 89.8028 0.851824 89.8028 1.90461C89.8028 2.95739 88.9498 3.8104 87.898 3.8104C86.8693 3.8104 85.9922 2.95739 85.9922 1.90461Z"
                                    fill="black"
                                />
                                <path
                                    d="M93.2011 12.6348C94.3794 13.8632 96.209 14.8909 98.5162 14.8909C100.471 14.8909 101.424 13.9635 101.424 13.0111C101.424 11.7576 99.9694 11.3311 98.0395 10.8795C95.3067 10.2533 91.7971 9.50061 91.7971 5.76522C91.7971 2.9832 94.2037 0.727072 98.1398 0.727072C100.797 0.727072 103.003 1.529 104.658 3.05753L102.677 5.66578C101.324 4.41228 99.5188 3.83525 97.889 3.83525C96.2842 3.83525 95.4321 4.53681 95.4321 5.53942C95.4321 6.6684 96.8362 7.01871 98.767 7.4703C101.524 8.09751 105.034 8.92448 105.034 12.6348C105.034 15.6928 102.853 18 98.3405 18C95.132 18 92.8248 16.9212 91.2461 15.3425L93.2011 12.6348Z"
                                    fill="black"
                                />
                                <path
                                    d="M107.595 14.6901V8.37249H105.59V5.58954H107.595V2.28065H110.804V5.58954H113.26V8.37249H110.804V13.8381C110.804 14.5898 111.205 15.1659 111.907 15.1659C112.383 15.1659 112.835 14.9903 113.01 14.8156L113.687 17.2213C113.211 17.6487 112.358 17.999 111.029 17.999C108.798 17.999 107.595 16.8468 107.595 14.6901Z"
                                    fill="black"
                                />
                                <path
                                    d="M115.301 5.59031H118.484V7.24522C119.336 6.16642 120.841 5.31434 122.345 5.31434V8.42346C122.12 8.37328 121.819 8.3231 121.468 8.3231C120.415 8.3231 119.011 8.92427 118.484 9.70201V17.6987H115.301V5.59031Z"
                                    fill="black"
                                />
                                <path
                                    d="M126.409 10.5042H132.375C132.3 9.35101 131.498 7.82153 129.392 7.82153C127.411 7.82153 126.559 9.30084 126.409 10.5042ZM129.392 5.28945C133.002 5.28945 135.459 7.99714 135.459 11.9583V12.6608H126.459C126.66 14.1893 127.888 15.4679 129.943 15.4679C131.072 15.4679 132.4 15.0163 133.177 14.2646L134.581 16.32C133.378 17.423 131.473 18 129.593 18C125.908 18 123.125 15.5181 123.125 11.6322C123.125 8.1226 125.706 5.28945 129.392 5.28945Z"
                                    fill="black"
                                />
                                <path
                                    d="M144.747 13.2866C144.221 12.5841 143.218 12.2338 142.19 12.2338C140.936 12.2338 139.909 12.8852 139.909 14.0634C139.909 15.1914 140.936 15.8438 142.19 15.8438C143.218 15.8438 144.221 15.4925 144.747 14.791V13.2866ZM144.747 16.445C143.92 17.4234 142.491 17.9995 140.911 17.9995C138.981 17.9995 136.699 16.6958 136.699 13.9891C136.699 11.155 138.981 10.1273 140.911 10.1273C142.516 10.1273 143.945 10.6291 144.747 11.6066V9.9266C144.747 8.69913 143.694 7.8963 142.09 7.8963C140.786 7.8963 139.583 8.37297 138.555 9.3254L137.352 7.19475C138.831 5.89015 140.736 5.2899 142.641 5.2899C145.399 5.2899 147.931 6.39193 147.931 9.87644V17.6984H144.747V16.445Z"
                                    fill="black"
                                />
                                <path
                                    d="M165.681 10.0528C165.681 8.92477 165.179 8.12194 163.876 8.12194C162.747 8.12194 161.82 8.87459 161.369 9.52689V17.6992H158.16V10.0528C158.16 8.92477 157.659 8.12194 156.354 8.12194C155.252 8.12194 154.324 8.87459 153.847 9.55105V17.6992H150.664V5.5908H153.847V7.16952C154.349 6.44288 155.954 5.28974 157.809 5.28974C159.589 5.28974 160.717 6.11675 161.169 7.49568C161.87 6.39272 163.475 5.28974 165.33 5.28974C167.562 5.28974 168.889 6.46796 168.889 8.94985V17.6992H165.681V10.0528Z"
                                    fill="black"
                                />
                                <path
                                    d="M34.4553 6.81092C34.4553 10.3177 31.6129 13.1602 28.1061 13.1602C24.6002 13.1602 21.7578 10.3177 21.7578 6.81092C21.7578 3.30412 24.6002 0.461716 28.1061 0.461716C31.6129 0.461716 34.4553 3.30412 34.4553 6.81092Z"
                                    fill="white"
                                />
                                <path
                                    d="M23.5764 6.81092C23.5764 10.3177 20.734 13.1602 17.2272 13.1602C13.7213 13.1602 10.8789 10.3177 10.8789 6.81092C10.8789 3.30412 13.7213 0.461716 17.2272 0.461716C20.734 0.461716 23.5764 3.30412 23.5764 6.81092Z"
                                    fill="black"
                                />
                                <path
                                    d="M12.6975 6.81092C12.6975 10.3177 9.8551 13.1602 6.3483 13.1602C2.84242 13.1602 0 10.3177 0 6.81092C0 3.30412 2.84242 0.461716 6.3483 0.461716C9.8551 0.461716 12.6975 3.30412 12.6975 6.81092Z"
                                    fill="white"
                                />
                            </svg>
                        </Link>
                    </div>
                    <div className="page-404__container">
                        <div className="page-404__wrapper">
                            <div className="page-404__title">404</div>
                            <div className="page-404__subtitle">
                                We can't seem to find
                                <br />
                                the page you're looking for.
                            </div>
                            <p className="page-404__text">
                                Here are some helpful links instead:
                            </p>
                            <ul className="page-404__nav">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About us</Link>
                                </li>
                                <li>
                                    <Link to="/products">
                                        Products and Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/news">News</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Status>
    );
}
