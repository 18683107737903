import fetch from 'isomorphic-fetch';
import { apiUrl } from '../../../../config/config';

export const getProductRequest = (props: any) => {
    const id: string = props.id;

    const requestUrl =
        apiUrl +
        'single-products/' +
        id +
        '?populate=ProductCards,ProductCards.CardOption,Image,Reference,ProductCards.ShowLogo,ProductCards.Image';

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    return fetch(requestUrl, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data);
};
