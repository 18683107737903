import React from "react";
import { Link } from "react-router-dom";
import { imgUrl, siteUrl } from "../../config/config";
import "./Footer.scss";

export function Footer() {

    return (
		<footer className="footer">
			<div className="container">
				<div className="footer-wrapper">
					<div className="footer-col footer-col-logo">
						<div className="footer-logo">
							<img src={imgUrl + "footer-logo.svg"} alt="" />
						</div>
						<div className="footer-excerpt">
							We provide global markets with high-quality mineral fertilizers and <br /> world-class freight to help feed the growing population
						</div>
					</div>
					<div className="footer-col footer-address">
						<strong>Address</strong>
						<a href="#">
							Office 01, Level 02
						</a>
						<a href="#">
							One JLT, Jumeirah Lake Towers
						</a>
						<a href="#">
							Dubai, UAE
						</a>
						<a href="#">
							PO Box: 71358
						</a>
					</div>
					<div className="footer-col">
						<strong>Phone</strong>
						<a href="tel:+97145942000">
							+971 4 594 2000
						</a>
						<strong>Email</strong>
						<a href="mailto:info@fertistream.com">
							info@fertistream.com
						</a>
					</div>
					{/*
					<div className="footer-subscribe">
						<form>
							<div className="form-subscribe__field">
								<input type="email" name="email-subscribe" id="email-subscribe" placeholder="Enter your email to get the latest news..." />
							</div>
							<button className="form-subscribe__send">Subscribe</button>
						</form>
					</div>
					*/}
				</div>
				<div className="copyright">
					<div className="copyright-text">
						FertiStream @ 2024. All rights reserved.
					</div>
					<div className="footer-menu">
						<ul>
							<li>
								<Link to={"/policies/terms-and-conditions"}>Terms and Conditions</Link>
							</li>
							<li>
								<Link to={"/policies/privacy-policy"}>Privacy Policy</Link>
							</li>
							<li>
								<Link to={"/policies/cookies-policy"}>Cookies Policy</Link>
							</li>
						</ul>
					</div>
					<div className="footer-soc">
						{
							/*
							<a href="#">
								<img src={imgUrl + "snap.svg"} alt="" />
							</a>
							<a href="#">
								<img src={imgUrl + "youtube.svg"} alt="" />
							</a>
							<a href="#">
								<img src={imgUrl + "facebook.svg"} alt="" />
							</a>
							<a href="#">
								<img src={imgUrl + "inst.svg"} alt="" />
							</a>
							<a href="#">
								<img src={imgUrl + "twitter.svg"} alt="" />
							</a>
							*/
						}
						<a href="https://www.linkedin.com/company/fertistream/posts/?feedView=all" target="_blank">
							<img src={imgUrl + "in.svg"} alt="" />
						</a>
					</div>
				</div>
			</div>
		</footer>
    );
}
