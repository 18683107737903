import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { imgUrl } from '../../config/config';
import { isMobile } from 'react-device-detect';

import { homepageSelector } from 'store/ducks/homepage/selectors/homepageSelector';

import cn from 'classnames';

import './Header.scss';
//import Link from "react-scroll/modules/components/Link";

export function Header() {
    const dispatch = useDispatch();
    const location = useLocation();

    const [menuOpen, menuOpenChange] = useState(false);

    const path = location.pathname;
    /* const path = location.pathname;

    const [selectValue, setSelectValue] = useState({
        value: 'ru',
        label: 'ru',
    });

    const options = [
        { value: 'ru', label: 'ru' },
        { value: 'en', label: 'en' },
    ];

    const handleChange = (e: any) => {
        setSelectValue(e);
        // dispatch(setLanguage(e));
    };

    const headerContent = useSelector(homepageSelector);*/

    return (
        <header className="header">
            <div className="container">
                <div className="header-wrapper">
                    <div className="header-logo">
                        <Link to="/">
                            <img
                                src={imgUrl + 'logo.svg'}
                                alt=""
                                className="home-logo"
                            />
                            <img
                                src={imgUrl + 'logo-dark.svg'}
                                alt=""
                                className="page-logo"
                            />
                            <img
                                src={imgUrl + 'logogreen.svg'}
                                alt=""
                                className="green-logo"
                            />
                        </Link>
                    </div>
                    <div className={`header-menu ${menuOpen ? 'show' : ''}`}>
                        <ul>
                            <li className="menu-item">
                                <NavLink
                                    to="/about"
                                    exact
                                    activeClassName="disabled"
                                    onClick={() => menuOpenChange(false)}
                                >
                                    About us
                                </NavLink>
                            </li>
                            <li className="menu-item menu-item-has-children">
                                <NavLink
                                    className={`${
                                        path.includes('products') ||
                                        path.includes('freight')
                                            ? 'disabled'
                                            : ''
                                    }`}
                                    exact
                                    activeClassName="disabled"
                                    to="/products"
                                    style={
                                        menuOpen
                                            ? { pointerEvents: 'none' }
                                            : null
                                    }
                                >
                                    Products and Services
                                </NavLink>
                                <ul className="submenu">
                                    {isMobile && (
                                        <li className="menu-item">
                                            <Link
                                                to="/products"
                                                onClick={() =>
                                                    menuOpenChange(false)
                                                }
                                            >
                                                All products
                                            </Link>
                                        </li>
                                    )}
                                    <li className="menu-item">
                                        <Link
                                            to="/products/1"
                                            onClick={() =>
                                                menuOpenChange(false)
                                            }
                                        >
                                            Nitrogen
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link
                                            to="/products/2"
                                            onClick={() =>
                                                menuOpenChange(false)
                                            }
                                        >
                                            Phosphates
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link
                                            to="/products/4"
                                            onClick={() =>
                                                menuOpenChange(false)
                                            }
                                        >
                                            Potassium
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link
                                            to="/products/3"
                                            onClick={() =>
                                                menuOpenChange(false)
                                            }
                                        >
                                            Complex fertilizers
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link
                                            to="/freight"
                                            onClick={() =>
                                                menuOpenChange(false)
                                            }
                                        >
                                            Freight and maritime logistics
                                        </Link>
                                    </li>
                                    <li className="menu-item menu-other-item">
                                        <Link
                                            to="/contacts"
                                            onClick={() =>
                                                menuOpenChange(false)
                                            }
                                        >
                                            <span>
                                                Please reach out to know more{' '}
                                                <br /> about available products
                                            </span>
                                            <img
                                                src={
                                                    imgUrl +
                                                    'circle-arrow-right.svg'
                                                }
                                                alt=""
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item">
                                <NavLink
                                    to="/news"
                                    exact
                                    activeClassName="disabled"
                                    onClick={() => menuOpenChange(false)}
                                >
                                    News
                                </NavLink>
                            </li>
                        </ul>
                        <div
                            className="language"
                            style={{ opacity: 0, cursor: 'default' }}
                        >
                            <span>ENG</span>
                            <div className="languages">
                                <div className="languages-item">DEU</div>
                                <div className="languages-item">PT</div>
                                <div className="languages-item">CHI</div>
                                <div className="languages-item">ARA</div>
                            </div>
                        </div>
                    </div>
                    <div className="header-other">
                        {/* <div className="header-search">
                            <Link to="/search">
                                <span><img src={imgUrl + "search1.svg"} alt="" /></span>
                            </Link>
                        </div> */}
                        <div
                            className="language"
                            style={{ opacity: 0, cursor: 'default' }}
                        >
                            <span>ENG</span>
                            <div className="languages">
                                <div className="languages-item">DEU</div>
                                <div className="languages-item">PT</div>
                                <div className="languages-item">CHI</div>
                                <div className="languages-item">ARA</div>
                            </div>
                        </div>
                        <Link to="/contacts" className="callback">
                            Contact us
                        </Link>
                        <div
                            className={`burger-menu ${
                                menuOpen ? 'active' : ''
                            }`}
                            onClick={() => menuOpenChange(!menuOpen)}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
